<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <!-- <vuexy-logo /> -->

        <h2 class="brand-text text-primary ml-1">
          Ongphra Wallpaper Creator
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="imgUrl" alt="Forgot password V2" style="max-height: 80vh;" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1">
            ลืมรหัสผ่าน? 🔒
          </b-card-title>
          <b-card-text class="mb-2">
            โปรดระบุอีเมลที่เคยลงทะเบียนกับเราไว้ เพื่อทำการขอเปลี่ยนรหัสผ่าน
          </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form class="auth-forgot-password-form mt-2" @submit.prevent="onSubmitForgetPassword">
              <b-form-group label="อีเมล" label-for="forgot-password-email">
                <validation-provider #default="{ errors }" name="อีเมล" rules="required|email">
                  <b-form-input
                    id="forgot-password-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="forgot-password-email"
                    placeholder="ongphra@gmail.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-overlay :show="loading" spinner-small>
                <b-button type="submit" variant="primary" block>
                  ยืนยัน
                </b-button>
              </b-overlay>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{ name: 'auth-login' }"> <feather-icon icon="ChevronLeftIcon" /> กลับไปเข้าสู่ระบบ </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import VuexyLogo from '@core/layouts/components/Logo.vue'
// import { BRow, BCol, BLink, BCardTitle, BCardText, BImg, BForm, BFormGroup, BFormInput, BButton } from 'bootstrap-vue'
import { required, email } from '@validations'
import store from '@/store/index'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    // VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: '',
      sideImg: require('@/assets/images/ongphra/forget_password_light.svg'),
      // validation
      required,
      email,
      phoneNumber: '',
      loading: false,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/ongphra/forget_password_dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    // validationForm() {
    //   this.$refs.simpleRules.validate().then(success => {
    //     if (success) {
    //       this.$toast({
    //         component: ToastificationContent,
    //         props: {
    //           title: 'This is for UI purpose only.',
    //           icon: 'EditIcon',
    //           variant: 'success',
    //         },
    //       })
    //     }
    //   })
    // },
    async onSubmitForgetPassword() {
      const validate = await this.$refs.simpleRules.validate()
      if (!validate) return

      this.loading = true
      const resp = await this.api.postV2('api/partners/forgot-password/request-email', { email: this.userEmail }, this)
      this.loading = false
      // console.log('onSubmitForgetPassword', resp)

      if (!resp) return

      if (resp.code === 200) {
        this.$router.push({ name: 'auth-reset-password', query: { token: resp.data?.token, email: this.userEmail } })
        return
      }

      if (resp.code === 405 || resp.code === 406) {
        this.gDisplayToast('ขอเปลี่ยนรหัสผ่านไม่สำเร็จ', resp.data?.message, 'danger')
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/pages/page-auth.scss';
</style>
